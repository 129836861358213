.picture-cropper-custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(2, 103, 169, 0.53);
  .picture-cropper-modal {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 556px;
    height: 400px;
    margin-top: 200px;
    background-color: #ffffff;
    overflow-y: auto;
    margin-left: calc(50vw - 130px);
    .picture-cropper-custom-body {
      padding: 16px;
      .picture-rotate-button {
        color: black !important;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    .custom-modal-footer {
      display: flex;
      justify-content: flex-end;
      padding: 16px;

      button {
        font-family: "bai-bold";
        width: 137px;
        height: 40px;
        margin-left: 8px;
        padding: 8px 16px;
        border: none;
        border-radius: 20px;
        background-color: #0267aa;
        color: #ffffff;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          color: #ffffff;
          background-color: #cdcdcd;
        }
      }
    }
  }
}
