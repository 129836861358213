.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 720px) {
    font-size: 12px;
  }

  .inputContainer {
    display: flex;
    input {
      border-radius: 10px;
      border: 1px solid #0a4d8e;
      width: 474px;
      height: 53px;
      margin-top: 15px;
      padding-left: 20px;
      @media (max-width: 720px) {
        width: 50vw;
      }
    }
    .range-picker-container {
      width: 474px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 720px) {
        width: 50vw;
        flex-wrap: wrap;
      }
    }

    .switch-buttons {
      margin-left: 10px;
      @media (max-width: 720px) {
        margin-left: 40px;
      }
      button {
        border: none;
        background-color: transparent;
        margin-top: 10px;
        margin-right: 40px;
        cursor: pointer;
        @media (max-width: 700px) {
          margin-right: 10px;
        }
      }
    }
    &.numberInputs {
      margin-left: 15px;
      @media (max-width: 720px) {
        width: 50vw;
        flex-wrap: wrap;
        margin-left: 0px;
      }
      input {
        width: 230px;
        margin-right: 15px;
        @media (max-width: 720px) {
          width: 50vw;
          margin-right: 0px;
        }
      }
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 476px;
    height: 53px;
    margin-bottom: 15px;
    @media (max-width: 720px) {
      flex-direction: column;
      width: 50vw;
      margin-top: 10px;
    }
    .custom-checkbox {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      input {
        margin-left: 10px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        @media (max-width: 720px) {
          width: 15px;
          height: 15px;
        }
      }
    }
    .number-input-container {
      width: calc(100% - 30px);
      text-align: center;
      @media (max-width: 720px) {
        width: 50vw;
      }
      input {
        @media (max-width: 720px) {
          margin-top: 0px;
        }
        border-radius: 10px;
        border: 1px solid #0a4d8e;
        width: 100%;
        height: 53px;
        margin-top: 15px;
        padding-left: 20px;
      }
    }
  }
  .select-wrapper {
    input {
      border-radius: 10px;
      border: 1px solid #0a4d8e;
      width: 474px;
      height: 53px;
      margin-top: 15px;
      padding-left: 20px;
      @media (max-width: 720px) {
        width: 50vw;
      }
    }
    .options-list {
      position: absolute;
      width: 474px;
      list-style: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      z-index: 2;
      @media (max-width: 720px) {
        width: 50vw;
      }
      li {
        padding: 8px 16px;
        margin-left: -40px;
        cursor: pointer;
        &.selected {
          background-color: transparent;
        }
      }
    }
  }
  .create-campaign-button {
    margin-top: 15px;
    margin-left: 335px;
    margin-bottom: 30px;
    width: 137px;
    height: 40px;
    color: #ffffff;
    border-radius: 20px;
    border: none;
    background-color: #0267aa;
    font-family: "bai-bold";
    cursor: pointer;
    &.disabled {
      color: #ffffff;
      background-color: #cdcdcd;
      cursor: not-allowed;
    }
    &.outdated {
      width: 270px;
      margin-left: 0;
      @media (max-width: 720px) {
        margin-left: 10px;
        width: 50vw;
      }
    }
    @media (max-width: 720px) {
      margin-left: 10px;
      width: 50vw;
    }
  }

  .tooltip {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
  }
}
