.version-buttons-container {
  display: flex;
  align-items: center;
  padding-top: 71px;
  justify-content: center;

  input {
    width: 66.323vw;
    height: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    border: none;
    padding-left: 21px;
    outline: none;
    @media (max-width: 1870px) {
      width: 64vw;
    }
    @media (max-width: 1800px) {
      width: 62vw;
    }
    @media (max-width: 1730px) {
      width: 60vw;
    }
    @media (max-width: 1630px) {
      width: 58vw;
    }
    @media (max-width: 1550px) {
      width: 56vw;
    }
    @media (max-width: 1490px) {
      width: 54vw;
    }
    @media (max-width: 1430px) {
      width: 52vw;
      margin-left: 0px;
    }
    @media (max-width: 1370px) {
      width: 48vw;
    }
    @media (max-width: 1300px) {
      width: 46vw;
    }
    @media (max-width: 1210px) {
      width: 44vw;
    }
    @media (max-width: 1090px) {
      width: 42vw;
    }
    @media (max-width: 1030px) {
      width: 62vw;
    }
    @media (max-width: 890px) {
      width: 60vw;
    }
    @media (max-width: 790px) {
      width: 58vw;
    }
    @media (max-width: 720px) {
      height: 30px;
      margin-left: 0px;
      font-size: 12px;
    }
    @media (max-width: 600px) {
      width: 56vw;
    }
    @media (max-width: 560px) {
      width: 52vw;
    }
    @media (max-width: 560px) {
      width: 48vw;
    }
  }
  img {
    position: absolute;
    transform: translateY(100%) translateX(-200%);
    @media (max-width: 720px) {
      transform: translateY(70%) translateX(-200%);
    }
  }

  .create-version-button {
    height: 40px;
    width: 149px;
    color: #ffffff;
    background-color: #0267aa;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: "bai-bold";
    margin-left: 20px;
    margin-bottom: 10px;
    @media (max-width: 720px) {
      height: 30px;
      width: 109px;
      font-size: 12px;
    }
    @media (max-width: 476px) {
      margin-left: 10px;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ffffff;
      background-color: #cdcdcd;
    }
  }
}
