.datePicker {
  width: 100%;
  position: relative;

  input {
    width: 230px !important;
    margin-right: 15px;
    @media (max-width: 720px) {
      width: 50vw !important;
      margin-right: 0px;
    }
  }
  input[type="date"]:required:invalid::-webkit-datetime-edit {
    opacity: 0;
  }
  input[type="date"]::before {
    color: #999;
    content: attr(placeholder);
    margin-right: 20px;
  }
}

.label {
  position: absolute;
  left: 0;
}
