.card-container {
  display: flex;
  padding-top: 71px;
  @media (max-width: 720px) {
    padding-top: 30px;
  }
}

.badge-card-row {
  display: flex;
  flex-wrap: wrap;
  .badge-card-container {
    width: 226px;
    height: 240px;
    margin-right: 20px;
    position: relative;
    border: none;
    overflow: hidden;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    .cards {
      position: relative;
      width: 157px;
      height: 155px;
      img {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        margin-left: 35px;
      }
      .badge-name {
        width: 226px;
        text-align: center;
        margin-top: 21px;
      }
      .badge-button {
        position: absolute;
        top: 18px;
        right: -52px;
        width: 18px;
        height: 16px;
        cursor: pointer;
        background-color: transparent;
        color: #0267aa;
        border: none;
      }
    }
    .new-badge-button {
      position: relative;
      text-align: center;
      text-decoration: underline;
      font-family: "bai-bold";
      width: 226px;
      height: 240px;
      background-color: transparent;
      border: none;
      color: #0267aa;
      cursor: pointer;
    }
  }
}
