.podium {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: -60px;
  @media (max-width: 720px) {
    margin-bottom: -20px;
  }
  .player {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-align: center;
    margin-right: 5px;
    width: 150px !important;
    position: relative;
    @media (max-width: 720px) {
      width: 70px !important;
    }
    &.position-1 {
      background-color: gold;
      height: 200px;
      @media (max-width: 720px) {
        height: 100px;
      }
    }

    &.position-2 {
      background-color: silver;
      height: 150px;
      z-index: 1;
      @media (max-width: 720px) {
        height: 75px;
      }
    }

    &.position-3 {
      background-color: #cd7f32;
      height: 100px;
      @media (max-width: 720px) {
        height: 50px;
      }
    }
    h2 {
      position: absolute;
      top: -40px;
      @media (max-width: 720px) {
        font-size: 14px;
        margin-left: -3vw;
      }
    }
    .position {
      font-size: 40px;
      font-family: "bai-bold";
      color: black;
      @media (max-width: 720px) {
        font-size: 20px;
      }
    }
  }
}

.leaderboard-buttons {
  button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    height: 33px;
    font-size: 18px;
    align-items: center;
    margin-right: 19px;
    margin-top: 15px;
    @media (max-width: 720px) {
      font-size: 12px;
      height: 20px;
      margin-left: 0px;
      margin-right: 5px;
    }
  }
}

.searchbox {
  margin-top: 15px;
  input {
    width: 1043px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    border: none;
    padding-left: 21px;
    outline: none;
    margin-left: 6px;
    @media (max-width: 1800px) {
      width: 52vw;
    }
    @media (max-width: 1730px) {
      width: 50vw;
    }
    @media (max-width: 1630px) {
      width: 48vw;
    }
    @media (max-width: 1550px) {
      width: 46vw;
    }
    @media (max-width: 1490px) {
      width: 44vw;
    }
    @media (max-width: 1430px) {
      width: 42vw;
      margin-left: 0px;
    }
    @media (max-width: 1370px) {
      width: 38vw;
    }
    @media (max-width: 1300px) {
      width: 34vw;
    }
    @media (max-width: 1210px) {
      width: 30vw;
    }
    @media (max-width: 1090px) {
      width: 26vw;
    }
    @media (max-width: 1030px) {
      width: 42vw;
    }
    @media (max-width: 890px) {
      width: 36vw;
    }
    @media (max-width: 790px) {
      width: 32vw;
    }
    @media (max-width: 720px) {
      height: 30px;
      margin-left: 0px;
      font-size: 12px;
    }
    @media (max-width: 560px) {
      width: 26vw;
    }
  }
  img {
    position: absolute;
    transform: translateY(100%) translateX(-200%);
  }
}
