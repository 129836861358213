.create-badge-button {
  height: 40px;
  width: 149px;
  color: #ffffff;
  background-color: #0267aa !important;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "bai-bold";
  margin-top: 15px;

  &.disabled {
    cursor: not-allowed;
    color: #ffffff;
    background-color: #cdcdcd;
  }
}

.uploader-with-info {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.switch-button-label {
  padding-top: 15px;
  padding-right: 15px;
}
.switch-button {
  background: black;
  margin-top: 15px;
  &.ant-switch {
    background-color: rgb(145, 145, 145);
  }
  &.ant-switch-checked {
    background-color: #0267aa;
  }
}
