.info-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 60px; /* Set the height to 60px */
  overflow: hidden; /* Enable scroll if needed */
  transition: opacity 0.5s ease-in-out;
  margin-top: 20px;

  &.show {
    display: flex; /* Show the modal */
    justify-content: center;
    align-items: center;
    height: 75px;
  }
  &.hide {
    opacity: 0; /* Hide the modal */
  }

  .info-modal-content {
    border-radius: 20px;
    background-color: #ffffff;
    text-align: center;
    padding-top: 20px;
    height: 100%;
  }

  .info-close {
    float: right;
    font-size: 28px;
    cursor: pointer;
    padding: 0 10px;
  }
}
