.layout-main-page {
  background-image: url("../../static/Group\ 2@1.5x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 232px;
    height: 85px;
    margin-top: 62px;
    margin-left: 66px;
  }
}
