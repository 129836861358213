.main-page {
  position: fixed;
  top: 0;
  padding-left: 310px;
  padding-top: 100px;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
  overflow-y: auto;
  .title {
    font-size: 20px;
    font-family: "bai-bold";
  }
  @media (max-width: 1030px) {
    padding-left: 70px;
  }

  @media (max-width: 720px) {
    padding-top: 70px;

    .title {
      font-size: 16px;
    }
  }
}
