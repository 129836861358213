.forgot-password-main-page {
  input {
    border: none;
    border-radius: 10px;
    transition: border-color 0.2s ease-in-out;
    background-color: #ffffff;
    width: 386px;
    height: 52px;
    margin-bottom: 15px;
    outline: none;
    padding-left: 21px;
    @media (max-width: 400px) {
      width: 340px;
    }
  }
  button {
    background-color: #0b4d8e;
    color: #ffffff;
    width: 386px;
    height: 48px;
    border-radius: 24px;
    border: none;
    font-weight: 100;
    cursor: pointer;
    margin-bottom: 10px;
    @media (max-width: 400px) {
      width: 340px;
    }
    &:hover {
      color: #f5f5f5;
    }
    &.disabled {
      cursor: not-allowed;
      color: #ffffff;
      background-color: #cdcdcd;
    }
  }
}
