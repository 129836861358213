.breadcrumb {
  font-size: 16px;
  margin-bottom: 24px;
  font-family: "bai";
  @media (max-width: 720px) {
    font-size: 14px;
  }

  .navigator {
    cursor: pointer;
  }
  .breadcrumb-item {
    font-size: 16px;
    color: #333;
  }
  .breadcrumb-separator {
    margin: 0 8px;
    color: #666;
  }
}
