.header-main {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 80px;
  display: grid;
  align-items: center;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.08);
  @media (max-width: 720px) {
    height: 60px;
  }
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-container {
      margin-left: 35px;
      @media (max-width: 720px) {
        margin-left: 5px;
        img {
          width: 100px;
        }
      }
    }

    .text-container {
      display: flex;
      align-items: center;
      margin-right: 35px;
      @media (max-width: 720px) {
        font-size: 12px;
      }

      img {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
