.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(2, 103, 169, 0.53);

  .modal {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 556px;
    height: 358px;
    margin-top: 221px;

    @media (max-width: 720px) {
      width: 50vw;
      height: fit-content;
      font-size: 12px;
    }

    .custom-modal-header {
      padding: 16px;
      font-family: "bai-bold";
      h2 {
        margin: 0;
        font-size: 18px;
        @media (max-width: 720px) {
          font-size: 14px;
        }
      }
    }

    .custom-modal-body {
      padding: 16px;

      input {
        width: 474px;
        height: 53px;
        border-radius: 10px;
        border: 1px solid #0a4d8e;
        padding-left: 20px;
        @media (max-width: 720px) {
          width: 100%;
        }
      }
      .checkbox {
        input {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
        label {
          margin-right: 10px;
        }
      }
      button {
        background-color: transparent;
        cursor: pointer;
        border: none;
        height: 33px;
        font-size: 18px;
        align-items: center;
        margin-right: 19px;
        margin-left: 20px;
        margin-top: 15px;
        @media (max-width: 720px) {
          font-size: 14px;
        }
      }
    }

    .custom-modal-footer {
      display: flex;
      justify-content: flex-end;
      padding: 16px;

      button {
        font-family: "bai-bold";
        width: 137px;
        height: 40px;
        margin-left: 8px;
        padding: 8px 16px;
        border: none;
        border-radius: 20px;
        background-color: #0267aa;
        color: #ffffff;
        cursor: pointer;

        @media (max-width: 720px) {
          width: fit-content;
        }

        &.disabled {
          cursor: not-allowed;
          color: #ffffff;
          background-color: #cdcdcd;
        }
      }
    }
  }
}
