div {
  .selected {
    border-bottom: 3px solid #0267aa !important;
    color: #0267aa;
    font-family: "bai-bold";
  }
  .not-selected{
    color: #696C77;
  }
}
