.left-menu {
  height: 100vh;
  width: 274px;
  padding-top: 100px;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 5px 0 4px rgba(0, 0, 0, 0.08);
  z-index: 9999;
  left: 0;
  top: 0;
  font-family: "bai-bold";

  @media (max-width: 1030px) {
    width: 40px;
  }

  @media (max-width: 720px) {
    padding-top: 70px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      button {
        background-color: transparent;
        padding: 0;
        width: 204px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        color: #434654;
        margin-bottom: 20px;
        margin-left: 35px;

        @media (max-width: 1030px) {
          margin-left: 10px;
          width: 25px;
          height: 25px;
        }
        div:first-child {
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 17px;
          @media (max-width: 1030px) {
            margin-left: 0px;
            padding: 2px 0 1.5px 2px;
          }
        }

        .menu-label {
          @media (max-width: 1030px) {
            display: none;
          }
        }

        img {
          width: 16px;
          height: 16px;
          display: flex;
          fill: white;
        }

        &:hover {
          background-color: #0267aa;
          color: #ffffff;
        }
        &.selected {
          background-color: #0267aa;
          color: #ffffff;
          @media (max-width: 1030px) {
            padding: 2px 0 1px 1px;
          }
        }
      }
    }
  }
  .logout {
    position: absolute;
    bottom: 15px;
    left: 0;
    button {
      background-color: transparent;
      padding: 0;
      width: 204px;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      color: #434654;
      margin-bottom: 20px;
      margin-left: 35px;

      @media (max-width: 1030px) {
        margin-left: 10px;
        width: 25px;
        height: 25px;
      }

      img {
        width: 14px;
        height: 14px;
        display: flex;
        margin-left: 25px;
        margin-right: 10px;
        @media (max-width: 1030px) {
          margin-left: 6px;
          width: 14px;
          height: 14px;
        }
      }
      .menu-label {
        @media (max-width: 1030px) {
          display: none;
        }
      }

      &:hover {
        background-color: #0267aa;
        color: #ffffff;
      }
    }
  }
}
