.editor-button {
  width: 37.5px;
  height: 22px;
  border: 1px solid #0a4d8e;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  font-family: "bai-bold";
  color: #0a4d8e;
  margin-top: 10px;

  &.active {
    background-color: #0a4d8e;
    color: #ffffff;
  }
}
.text-area {
  margin-top: 10px;
  border: 1px solid #0a4d8e;
  font-family: "bai";
  border-radius: 10px;
  width: 475px;
  min-height: 113px;
  height: 113px;
  padding-top: 17.5px;
  padding-left: 19.5px;

  @media (max-width: 720px) {
    width: 50vw;
  }

  &:focus {
    border: 1px solid #0a4d8e;
  }
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}
