.message-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .message-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .message-modal__title {
      font-size: 24px;
      font-weight: bold;
    }
    .close-button {
      font-family: "bai-bold";
      background-color: transparent;
      border: none;
      font-size: 16px;
      cursor: pointer;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      width: 5px;
    }
  }
  .message-modal__message {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .message-modal__footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    .button {
      border: none;
      height: 40px;
      width: 100px;
      color: #ffffff;
      background-color: #0267aa;
      border-radius: 20px;
      margin-left: 40px;
      cursor: pointer;
      font-family: "bai-bold";
    }
    .button-left-margin {
      margin-left: 10px;
    }
  }
}
