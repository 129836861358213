.picture-uploader-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .background-container {
    position: relative;
    margin-top: 17px;
    width: 474px;
    height: 213px;

    @media (max-width: 720px) {
      width: 50vw;
      height: 30vw;
    }

    input {
      position: absolute;
      width: 474px;
      height: 100%;
      cursor: pointer;
      z-index: 2;
      background-color: #0a4d8e;
      margin-top: 17px;
      left: 0;
      display: none;
      @media (max-width: 720px) {
        width: 50vw;
        margin-top: 0px;
      }
    }
    button {
      position: absolute;
      top: 17px;
      right: 323px;
      font-size: 16px;
      height: 20px;
      cursor: pointer;
      z-index: 1;
      background-color: transparent;
      border: none;
      text-decoration: underline;
      color: #0a4d8e;
      font-family: "bai-bold";
      @media (max-width: 720px) {
        font-size: 12px;
        right: 30vw;
      }
      &.delete-picture {
        color: #c30870;
        z-index: 3;
      }
    }
    .picture-preview-container {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #0a4d8e;
      border-radius: 10px;

      &.drag-over {
        border: 1px dashed #0a4d8e;
      }

      .picture-preview {
        max-height: 190px;
        @media (max-width: 720px) {
          max-width: 50vw;
        }
      }
    }
  }
}
