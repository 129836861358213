.table-container {
  width: 90%;
  overflow-x: auto;
  margin: 0 auto;
  padding-top: 32px;

  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 8px #f4f4f4;
    @media (max-width: 720px) {
      font-size: 12px;
    }
    th {
      text-align: left;
      padding: 12px 15px;
      font-family: "bai-bold";
      background-color: #f4f4f4 !important;

      .inline-container {
        display: flex;

        img {
          cursor: pointer;
          transition: transform 0.3s ease-in-out;
          &.rotate-180 {
            transform: rotate(180deg);
          }
          margin-left: 5px;
        }

        .recicle-position {
          margin-left: 5px;
        }
      }
    }

    td {
      padding: 12px 15px;
      border-bottom: 1px solid #ddd;
      font-family: "bai";
      @media (max-width: 720px) {
        background-color: #ffffff;
        overflow-x: hidden;
      }
      .table-img {
        width: 84px !important;
        height: 38px !important;
      }

      .table-icon {
        border: none !important;
        cursor: pointer;
      }

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    tr:hover td {
      background-color: #f5f5f5;
    }
  }

  .selected-row {
    background-color: #0267aa;
    color: #ffffff;

    &:hover {
      color: black;
      background-color: #0267aa;
    }
  }

  .pagination {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    margin-bottom: 20px;
    @media (max-width: 720px) {
      font-size: 12px;
    }
    .prev-button,
    .next-button {
      cursor: pointer;
      width: 25px;
      height: 21px;
      border-radius: 6px;
      border: none;
    }

    .prev-button:hover,
    .next-button:hover {
      background-color: #f5f5f5;
    }

    .prev-button.disabled,
    .next-button.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .page-info {
      margin: 0 14px;
      font-size: 16px;
      font-family: "bali-bold";
      z-index: 2;
    }
  }
}
