.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  .animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    position: relative;
  }

  .circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    position: relative;

    &.failed {
      background-color: #f44336;
    }
    &.success {
      background-color: #3cf436;
    }
  }

  .cross {
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 80%;

    .line1,
    .line2 {
      position: absolute;
      width: 100%;
      height: 5px;
      background-color: #fff;
      transform-origin: center;
    }

    .line1 {
      transform: rotate(45deg);
      &.success {
        width: 40%;
        left: 11.5%;
        top: 5%;
        transform: rotate(45deg);
      }
    }

    .line2 {
      transform: rotate(-45deg);
      &.success {
        left: 35%;
        width: 65%;
      }
    }
  }

  .animate .circle {
    animation: pulse 1s infinite alternate;
  }
  .animate .cross {
    animation: pulse 1s infinite alternate;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
}
