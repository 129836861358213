.forgot-password-input {
  input {
    width: calc(100vw - 15px) !important;
    max-width: 556px;
  }
}
.forgot-password-button {
  border: none;
  height: 40px;
  width: 139px;
  color: #ffffff;
  background-color: #0267aa !important;
  border-radius: 20px;
  margin-top: 15px;
  cursor: pointer;
  font-family: "bai-bold";
  &.disabled {
    cursor: not-allowed;
    color: #ffffff;
    background-color: #cdcdcd !important;
  }
}

.forgot-password-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 15px);
  span {
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    font-family: "bai-bold";
  }
}
