.container {
  .create-company-button {
    height: 40px;
    width: 149px;
    color: #ffffff;
    background-color: #0267aa;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: "bai-bold";
    margin-top: 15px;

    &.disabled {
      cursor: not-allowed;
      color: #ffffff;
      background-color: #cdcdcd;
    }
  }
  span {
    color: rgb(255, 0, 0);
  }
}
