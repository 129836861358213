@font-face {
  font-family: "bai-bold";
  src: url("./BaiJamjuree-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "bai";
  src: url("./BaiJamjuree-Regular.ttf") format("truetype");
  font-style: normal;
}

/* Add more @font-face rules if you have other font weights or styles. */

/* Apply the font to the body or any other desired elements */
body {
  font-family: "bai", sans-serif;
}
