.container {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 10px;
  }
}
.create-admin-button {
  border: none;
  height: 40px;
  width: 170px;
  color: #ffffff;
  background-color: #0267aa !important;
  border-radius: 20px;
  cursor: pointer;
  font-family: "bai-bold";
  margin-top: 15px;
  margin-left: 10px;
  &.disabled {
    cursor: not-allowed;
    color: #ffffff;
    background-color: #cdcdcd !important;
  }
}
